import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import * as styles from './OfferingCardTimer.module.scss';

interface Props {
  deadline: string;
}

const OfferingCardTimer = ({ deadline }: Props) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [expired, setExpired] = useState(false);

  const setTime = () => {
    const end = moment.tz(deadline, 'America/New_York').endOf('day');
    const now = moment();

    const diff = end.diff(now);

    let newDays = 0;
    let newHours = 0;
    let newMinutes = 0;
    let newExpired = true;

    if (Math.sign(diff) === 1) {
      const diffDuration = moment.duration(diff);
      newDays = Math.floor(diffDuration.asDays());
      newHours = diffDuration.hours();
      newMinutes = diffDuration.minutes();
      newExpired = false;
    }

    setDays(newDays);
    setHours(newHours);
    setMinutes(newMinutes);
    setExpired(newExpired);
  };

  useEffect(() => {
    let timerInterval = null;

    if (deadline) {
      setTime();
      timerInterval = setInterval(setTime, 30000);
    }

    return () => {
      if (timerInterval) clearInterval(timerInterval);
    };
  }, [deadline]); // eslint-disable-line react-hooks/exhaustive-deps

  const lessThanOneDayLeft = days < 1;

  const getHours = () => {
    if (hours > 0) {
      return `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
    }
    return '';
  };

  const getMinutes = () => `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;

  const getDays = () => `${days} ${days === 1 ? 'day' : 'days'}`;

  return deadline ? (
    <div className={`${styles.timer} ${lessThanOneDayLeft ? styles.red : ''}`}>
      {expired ? (
        'Closed'
      ) : (
        <>{lessThanOneDayLeft ? `${getHours()} ${getMinutes()}` : getDays()}</>
      )}
    </div>
  ) : null;
};

export default OfferingCardTimer;
