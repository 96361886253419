import { Alert, Card, Tooltip } from '@equitymultiple/react-eui';
import { SingleInvestment } from 'containers/Portfolio/types';
import React from 'react';

import utils from '../../../../utilities/utils';
import { getPaymentStatusDescription } from '../../helpers';
import PaymentsTable from '../PaymentsTable/PaymentsTable';
import * as styles from './InvestmentPayments.module.scss';

interface Props {
  investment: SingleInvestment;
}

const InvestmentPayments = ({ investment }: Props) => {
  const getMessage = () => {
    const paymentStatus = investment.offering.payment_status;
    const nextPaymentDate =
      utils.dateFormat(investment.offering.next_payment_on) || 'TBD';
    const type = paymentStatus === 'On Time' ? 'positive' : 'warning';
    let paymentStatusString = paymentStatus.toLowerCase();
    if (paymentStatus === 'Payment Unavailable')
      paymentStatusString = 'unavailable';

    return (
      investment.closing.stage === 'cashflowing' && (
        <Alert className={styles.inlineMessage} type={type}>
          <span className="text" data-testid="payment-status-text">
            Your {investment.offering.distribution_frequency.toLowerCase()}{' '}
            payment is {paymentStatusString}. Next target payment date is{' '}
            {nextPaymentDate}.
          </span>
          <Tooltip
            className="info-icon-margin-left"
            infoIcon
            tooltipContent={
              <div>{getPaymentStatusDescription(paymentStatus)}</div>
            }
          />
        </Alert>
      )
    );
  };

  return (
    <div>
      {getMessage()}
      <Card className="padding0">
        <PaymentsTable investmentId={investment.id} />
      </Card>
    </div>
  );
};

export default InvestmentPayments;
