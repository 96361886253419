import moment from 'moment';

export default {
  arrayEquals(a, b) {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  },

  // Converts an array into a comma separated string, including "and" for the last element
  arrayToCommaSeparatedString(arr: string[]) {
    return [arr.slice(0, -1).join(', '), arr.slice(-1)[0]].join(
      arr.length < 2 ? '' : ' and '
    );
  },

  bytesToSize(bytes) {
    if (typeof bytes === 'number' && bytes > -1) {
      if (bytes === 0) return '0 Bytes';
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      const sizeIndex = Math.floor(Math.log(bytes) / Math.log(1024));
      return Math.round(bytes / 1024 ** sizeIndex) + ' ' + sizes[sizeIndex];
    }
    return null;
  },

  camelCase(string: string) {
    return string
      .toLowerCase()
      .replace(/[-_ ][a-z]/g, group => group.slice(-1).toUpperCase());
  },

  capitalize(string) {
    if (!string || typeof string !== 'string') return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  cloneObject(object) {
    if (typeof object !== 'object') return null;
    return JSON.parse(JSON.stringify(object));
  },

  createAndClickDownloadLink(fileName, href) {
    if (
      fileName &&
      href &&
      typeof fileName === 'string' &&
      typeof href === 'string'
    ) {
      const link = document.createElement('a');

      link.setAttribute('href', href);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },

  currencyStringToNumber(string) {
    if (typeof string !== 'string') return null;
    return Number(string.replace(/[^0-9.-]+/g, ''));
  },

  dateFormat(date, dateFormat?) {
    if (date && moment(date, dateFormat).isValid())
      return new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' });
    return date;
  },

  debounce(callback, wait) {
    let timeout = null;
    return (...args) => {
      const next = () => callback(...args);
      clearTimeout(timeout);
      timeout = setTimeout(next, wait);
    };
  },

  // Given an object, a key of that object, and a search function, executes the search function recursively
  // on that key and any child objects of the key with the same key, returning an object that returns true for the search function
  deepObjectSearch(object, property, functionToCheck) {
    if (
      typeof object === 'object' &&
      typeof property === 'string' &&
      typeof functionToCheck === 'function'
    ) {
      if (
        Object.prototype.hasOwnProperty.call(object, property) &&
        functionToCheck(object[property]) === true
      )
        return object;

      for (let i = 0; i < Object.keys(object).length; i++) {
        if (
          object[Object.keys(object)[i]] &&
          typeof object[Object.keys(object)[i]] === 'object'
        ) {
          const o = this.deepObjectSearch(
            object[Object.keys(object)[i]],
            property,
            functionToCheck
          );
          if (o != null) return o;
        }
      }
    }

    return null;
  },

  downloadFile(blobContent, fileName) {
    if (blobContent && typeof fileName === 'string') {
      const blob = new Blob([blobContent]);
      if (blob instanceof Blob) {
        const url = window.URL.createObjectURL(blob);
        this.createAndClickDownloadLink(fileName, url);
      }
    }
  },

  downloadResponse(response) {
    if (response) {
      let filename = '';
      const disposition = response.xhr.getResponseHeader('Content-Disposition');
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1])
          filename = matches[1].replace(/['"]/g, '');
      }

      const url = window.URL.createObjectURL(response.body);

      if (filename) this.createAndClickDownloadLink(filename, url);
      else window.location.assign(url);
    }
  },

  fileIsPdf(url) {
    if (url && typeof url === 'string') {
      const removeParams = url.split('?')[0];
      const fileType = removeParams
        .split('.')
        [removeParams.split('.').length - 1].toLowerCase();
      if (fileType === 'pdf') return true;
    }
    return false;
  },

  formatBoolean(value) {
    if (value === true) return 'true';
    if (value === false) return 'false';
    return value;
  },

  formatCurrency(amount: string | number, decimal = 2, abs = false) {
    const amountToFormat =
      typeof amount === 'string' ? parseFloat(amount) : amount;
    const signDisplay = abs ? 'never' : 'auto';

    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: decimal,
      minimumFractionDigits: decimal,
      signDisplay
    }).format(amountToFormat as number);
  },

  generateAndDownloadCsv(data, columnHeadings = '', fileName = null) {
    if (
      data &&
      Array.isArray(data) &&
      fileName &&
      typeof fileName === 'string'
    ) {
      let csv = '';
      if (columnHeadings && typeof columnHeadings === 'string')
        csv = `${columnHeadings} \r\n`;

      data.forEach(rowArray => {
        if (Array.isArray(rowArray)) {
          // Wrap every value in double quotes in case it contains a comma
          const rowArrayWithQuotes = rowArray.map(column => '"' + column + '"');
          const row = rowArrayWithQuotes.join(',');
          csv += row + '\r\n';
        }
      });

      const blob = new Blob([csv], {
        type: 'text/csv;encoding:utf-8'
      });

      const href = URL.createObjectURL(blob);

      this.createAndClickDownloadLink(`${fileName}.csv`, href);
    }
  },

  getAfterLoginPath(path?: string) {
    let afterLoginPath = path;
    if (afterLoginPath === '/' && this.getLocalStorage('referer')) {
      afterLoginPath = this.getLocalStorage('referer');
      this.removeLocalStorage('referer');
    }
    if (
      !afterLoginPath ||
      afterLoginPath.includes('/null') ||
      afterLoginPath.includes('/undefined')
    )
      afterLoginPath = '/';
    return afterLoginPath;
  },

  getCookie(name) {
    if (name) {
      const cookieName = name + '=';
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1);
        if (c.indexOf(cookieName) === 0)
          return c.substring(cookieName.length, c.length);
      }
    }

    return null;
  },

  getLocalStorage(name) {
    const supportsLocalStorage = this.testLocalStorageSupport();
    return supportsLocalStorage ? localStorage.getItem(name) : null;
  },

  getQuarterStartEndDates(quarter, year) {
    if (quarter && year) {
      let startDate;
      let endDate;
      switch (quarter) {
        case 1:
          startDate = `${year}-01-01`;
          endDate = `${year}-03-31`;
          break;
        case 2:
          startDate = `${year}-04-01`;
          endDate = `${year}-06-30`;
          break;
        case 3:
          startDate = `${year}-07-01`;
          endDate = `${year}-09-30`;
          break;
        case 4:
          startDate = `${year}-10-01`;
          endDate = `${year}-12-31`;
          break;
        default:
          return null;
      }
      return [startDate, endDate];
    }
    return null;
  },

  isIOS() {
    return (
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    );
  },

  isString(value = null) {
    if (value) return typeof value === 'string' || value instanceof String;
    return false;
  },

  isValidUrl(value) {
    if (value && typeof value === 'string') {
      let url;

      try {
        url = new URL(value);
      } catch (_) {
        return false;
      }

      return url.protocol === 'http:' || url.protocol === 'https:';
    }
    return false;
  },

  kebabCase(value) {
    if (value) {
      return value
        .toLowerCase()
        .replace(/[^\w\s]/gi, '')
        .replace('_', '-')
        .replace(/\s+/g, '-');
    }
    return null;
  },

  normalizeBoolean(value) {
    if (value === 'true') return true;
    if (value === 'false') return false;
    return value;
  },

  numberWithCommas(num) {
    if (num && (typeof num === 'string' || typeof num === 'number'))
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return null;
  },

  removeLocalStorage(name) {
    const supportsLocalStorage = this.testLocalStorageSupport();
    if (supportsLocalStorage) localStorage.removeItem(name);
  },

  round(number, decimals = 0) {
    if (number === 0) return 0;
    if (
      decimals &&
      typeof decimals === 'number' &&
      number &&
      !Number.isNaN(parseFloat(number)) &&
      Number.isFinite(number)
    ) {
      const decimalPower = 10 ** decimals;
      return Math.round(parseFloat(number) * decimalPower) / decimalPower;
    }
    return number || null;
  },

  rxSanitizeText(textString) {
    // Sanitizes search string to be regex compliant.
    if (typeof textString !== 'string') return null;
    return textString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  },

  setCookie(name, value, expirationDays = 1) {
    if (
      name &&
      value &&
      ['number', 'string'].includes(typeof name) &&
      ['number', 'string'].includes(typeof value)
    ) {
      let expires = '';
      if (expirationDays) {
        const d = new Date();
        d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000);
        expires = ' expires=' + d.toUTCString();
      }
      document.cookie = name + '=' + value + ';' + expires + '; path=/';
    }
  },

  setLocalStorage(name, value) {
    const supportsLocalStorage = this.testLocalStorageSupport();
    if (supportsLocalStorage) localStorage.setItem(name, value);
  },

  // Given an array of fields such as ['field1', 'field2', 'field3'], and a value 'myValue'
  // this function will return this object:
  // {
  //   field1: {
  //     field2: {
  //       field3: myValue
  //     }
  //   }
  // }
  setValueToNestedObjectField(fields, value) {
    if (fields && Array.isArray(fields) && value) {
      const reducer = (total, item, index, arr) => ({
        [item]: index + 1 < arr.length ? total : value
      });
      return fields.reduceRight(reducer, {});
    }
    return null;
  },

  snakeCaseToTitleCase(string) {
    if (string && typeof string === 'string') {
      return string
        .split('_')
        .map(element =>
          element[0] ? element[0].toUpperCase() + element.substr(1) : null
        )
        .join(' ');
    }
    return null;
  },

  startCase(str) {
    if (str && typeof str === 'string')
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    return null;
  },

  testLocalStorageSupport() {
    try {
      localStorage.setItem('x', 'x');
      localStorage.removeItem('x');
      return true;
    } catch (e) {
      return false;
    }
  },

  titalize(str) {
    if (str && typeof str === 'string') {
      const result = str
        .split(' ')
        .map(
          word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
      return result.join(' ');
    }
    return null;
  },

  // Takes any data type, iterates through it in the case of objects or arrays, and trims any strings found
  // Optionally pass an array of object keys that should be skipped as the second parameter
  trimStrings(data, keysToIgnore = []) {
    const dataType = typeof data;

    // If object or array, iterate through values and call this function again
    // If string, trim it
    // Ignore other data types
    switch (dataType) {
      case 'object':
        // Ignore Blobs (including files) because they're immutable
        if (!(data instanceof Blob)) {
          Object.keys(data).forEach(key => {
            if (data[key] && !keysToIgnore.includes(key))
              data[key] = this.trimStrings(data[key], keysToIgnore);
          });
        }
        break;
      case 'string':
        // eslint-disable-next-line
        data = data.trim();
        break;
      default:
        return data;
    }
    return data;
  },

  // Returns keys for the nested form
  // parentKey = address_attributes
  // selectedKeys = ['address_attributes.address', 'address_attributes.address2', 'address_attributes.city']
  // Output = ['address', 'address2', 'city']
  getNestedKeys(parentKey: string, selectedKeys: string[]) {
    return selectedKeys
      .filter(key => key.startsWith(parentKey + '.'))
      .map(key => key.substring(parentKey.length + 1));
  },

  parseToNumber(value: number | string) {
    const sanitizedValue = String(value).replace(/[^0-9.]/g, '');

    const number = parseFloat(sanitizedValue);

    return number;
  }
};
