import { Button, Card, EMLoadingIcon } from '@equitymultiple/react-eui';
import HeaderSimple from 'components/HeaderSimple/HeaderSimple';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-grid-system';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAdBlockerModalContent } from 'redux/actions/modals';
import {
  loadUserUpdateAlerts,
  updateUserUpdateAlert
} from 'redux/actions/user-update-alert';
import { User } from 'types/api/user';
import { Dispatch } from 'types/redux';
import { adBlockerIntercomMessage } from 'utilities/constants';
import EmAnalytics from 'utilities/em_analytics';
import { handleErrorResponse } from 'utilities/errorHandlers';

import Confirmation from './Confirmation';
import { updateAlertDescriptions, updateAlertStatuses } from './contents';
import AnnualAddressVerification from './specificAlerts/AnnualAddressVerification/AnnualAddressVerification';
import PrivacyPolicyTos from './specificAlerts/PrivacyPolicyTos';
import Residence from './specificAlerts/Residence';
import TaxFilingStatus from './specificAlerts/TaxFilingStatus';
import { UpdateAlert } from './types';
import * as styles from './UserUpdateAlerts.module.scss';
import * as sharedStyles from './UserUpdateAlertsShared.module.scss';

interface Props {
  dispatch: Dispatch;
  loaded: boolean;
  loading: boolean;
  user: User;
  userUpdateAlerts: UpdateAlert[];
}

const UserUpdateAlerts = ({
  dispatch,
  loading,
  loaded,
  userUpdateAlerts
}: Props) => {
  const navigate = useNavigate();
  const [activeAlert, setActiveAlert] = useState('');
  const [alertStates, setAlertStates] = useState([]);
  const [allAlertsComplete, setAllAlertsComplete] = useState(false);

  useEffect(() => {
    document.title = 'Review | EquityMultiple';

    if (!loaded) {
      dispatch(loadUserUpdateAlerts())
        .then(res => {
          if (res?.update_alerts?.length === 0) {
            navigate('/');
          } else {
            const newAlertStates = [];
            if (res.update_alerts) {
              res.update_alerts.forEach(alert => {
                const description = updateAlertDescriptions[alert.title];
                // Only display alerts that have descriptions listed in the contents file
                if (description) {
                  newAlertStates.push({
                    title: alert.title,
                    description,
                    status: updateAlertStatuses.upcoming
                  });
                }
              });
            }

            if (newAlertStates.length) {
              // Set the first alert to active so it's displayed when "Next" is clicked
              newAlertStates[0].status = updateAlertStatuses.active;

              setAlertStates(newAlertStates);
            } else {
              // If there are only unhandled alerts then send the user to /invest
              navigate('/');
            }
          }
        })
        .catch(error => handleErrorResponse(navigate, error));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateAlertStates = () => {
    const newAlertStates = [...alertStates];
    let newAllAlertsComplete = false;

    const completedAlert = newAlertStates.find(
      alertState => alertState.status === updateAlertStatuses.active
    );
    if (completedAlert) completedAlert.status = updateAlertStatuses.completed;

    const nextAlert = newAlertStates.find(
      alertState => alertState.status === updateAlertStatuses.upcoming
    );
    if (nextAlert) nextAlert.status = updateAlertStatuses.active;
    else newAllAlertsComplete = true;

    setActiveAlert(null);
    setAlertStates(newAlertStates);
    setAllAlertsComplete(newAllAlertsComplete);
  };

  const submitAlert = alert => {
    dispatch(updateUserUpdateAlert(alert)).then(res => {
      if (res.date_considered_complete) {
        EmAnalytics.track('Update Alert', 'Account', {
          action_taken: alert.action,
          alert_title: alert.title
        });
        handleUpdateAlertStates();
      }
    });
  };

  const goToNextAlert = () => {
    const nextActiveAlert = alertStates.find(
      alertState => alertState.status === updateAlertStatuses.active
    );

    const nextActiveAlertTitle = nextActiveAlert ? nextActiveAlert.title : null;
    setActiveAlert(nextActiveAlertTitle);
  };

  const renderActiveAlert = () => {
    const alert = userUpdateAlerts.find(
      updateAlert => updateAlert.title === activeAlert
    );

    const sharedProps = {
      alert,
      handleSubmitAlert: submitAlert
    };

    switch (activeAlert) {
      case 'tax_address_verification':
        return (
          <AnnualAddressVerification
            handleUpdateAlertStates={handleUpdateAlertStates}
          />
        );
      case 'privacy_policy_and_terms_of_service_opt_in':
        return <PrivacyPolicyTos {...sharedProps} />;
      case 'individual_joint_tax_filing_status_update':
        return <TaxFilingStatus {...sharedProps} />;
      case 'residence_status_update':
        return <Residence {...sharedProps} />;
      // Redirect to invest if there's no component for the update alert
      default:
        navigate('/');
        return null;
    }
  };

  return loading ? (
    <EMLoadingIcon />
  ) : (
    <div>
      <HeaderSimple />
      <Container className={styles.container}>
        {!allAlertsComplete ? (
          <>
            <div className={styles.header}>Account Review</div>
            <div className={styles.subHeader}>
              Please review the following information
            </div>
            <Card className={`card ${styles.card}`}>
              {!activeAlert ? (
                <div className={styles.intro}>
                  <h3 className="margin-top-0 margin-xx">
                    Let&apos;s Get Started
                  </h3>
                  <p className="margin-xx size-16">
                    We are required to keep your information up-to-date in order
                    to stay compliant with regulatory requirements. As with all
                    information you provide to us, it is kept private and
                    secure. This should only take a few minutes.
                  </p>
                  <div className="numbered-todo-list">
                    {alertStates.map((alertState, index) => (
                      <div
                        className={`item ${alertState.status}`}
                        key={alertState.title}
                      >
                        <div className="item-icon">
                          <div className="item-number">{index + 1}</div>
                        </div>
                        <div className="item-text">
                          {alertState.description}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className={sharedStyles.continueButtonWrap}>
                    <Button onClick={goToNextAlert} style={{ width: 200 }}>
                      Next
                    </Button>
                  </div>
                </div>
              ) : (
                <>{renderActiveAlert()}</>
              )}
            </Card>

            <Card className="card">
              <h3 className="margin-top-0 margin-x">Have Questions?</h3>
              <div className="size-16">
                We have a dedicated Investment Relations team ready to assist,
                you can contact them directly through{' '}
                <button
                  className="text-link"
                  onClick={() => {
                    if (window.Intercom) window.Intercom('show');
                    else
                      dispatch(
                        setAdBlockerModalContent(adBlockerIntercomMessage)
                      );
                  }}
                  type="button"
                >
                  live chat
                </button>
                .
              </div>
            </Card>
          </>
        ) : (
          <Confirmation />
        )}
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    userUpdateAlerts: state.userUpdateAlerts.userUpdateAlerts,
    loading: state.userUpdateAlerts.loadingUpdateAlerts,
    loaded: state.userUpdateAlerts.loadedUpdateAlerts
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(UserUpdateAlerts);
