import {
  InvestmentClosing,
  InvestmentOffering
} from 'containers/Portfolio/types';
import React from 'react';
import { Link } from 'react-router-dom';

import * as styles from './OfferingCard.module.scss';

interface Props {
  closing: InvestmentClosing;
  offering: InvestmentOffering;
}

const OfferingCard = ({ closing, offering }: Props) => {
  const handleCardClick = () => {
    window.open(`/invest/${offering.id}/closings/${closing.id}`, '_blank');
  };

  return (
    <div
      className={styles.offeringCard}
      role="link"
      onKeyDown={() => {}}
      onClick={handleCardClick}
      tabIndex={0}
    >
      <div
        className={styles.cardHeader}
        style={{
          backgroundImage: `linear-gradient(180deg, rgba(42, 43, 48, 0) 25%, rgba(42, 43, 48, 0.75) 100%), url(${offering.card_image})`
        }}
      />
      <div className={styles.cardContent}>
        <div className={styles.propertyInfo}>{offering.location}</div>
        <h4 className={styles.offeringTitle}>{offering.title}</h4>
      </div>
      <Link
        to={`/invest/${offering.id}/closings/${closing.id}`}
        className={styles.button}
        target="_blank"
        rel="noopener noreferrer"
      >
        View Offering Memo
      </Link>
    </div>
  );
};

export default OfferingCard;
