import { Button, FilterRange, FilterSelect } from '@equitymultiple/react-eui';
// eslint-disable-next-line import/no-unresolved
import { NonAsyncValue } from '@equitymultiple/react-eui/dist/types/Select';
import { ActivePillar } from 'containers/Invest/types';
import ClearFilter from 'images/icons/clear-filter.svg';
import IconEarn from 'images/icons/earn.svg';
import IconFilters from 'images/icons/filters.svg';
import IconGrow from 'images/icons/grow.svg';
import IconAll from 'images/icons/invest-all.svg';
import IconKeep from 'images/icons/keep.svg';
import React, { Ref, useState } from 'react';
import EmAnalytics from 'utilities/em_analytics';

import * as styles from './OfferingFilters.module.scss';

const percentageInputProps = {
  mask: '9[9][.9[9]]%',
  inputMaskOptions: {
    suffix: '%',
    showMaskOnFocus: false
  }
};

const monthsInputProps = {
  mask: '9[9][9] months',
  inputMaskOptions: {
    suffix: ' months',
    showMaskOnFocus: false
  }
};

interface SelectInstance {
  clearValue: () => void;
}

interface Props {
  activeHoldPeriod?: number[];
  activePillar: ActivePillar;
  activePropertyType: string[];
  activeTargetReturn?: number[];
  onClearFilter: () => void;
  onHoldPeriodChange: (minValue: number, maxValue: number) => void;
  onHoldPeriodClear: () => void;
  onPillarChange: (pillar: ActivePillar) => void;
  onPropertyTypeChange: (value: NonAsyncValue) => void;
  onTargetReturnChange: (minValue: number, maxValue: number) => void;
  onTargetReturnClear: () => void;
  propertyTypeOptions: { label: string; value: string }[];
  propertyTypeRef: Ref<SelectInstance>;
}

const OfferingFilters = ({
  activePillar,
  activePropertyType,
  activeTargetReturn,
  activeHoldPeriod,
  onClearFilter,
  onPillarChange,
  onPropertyTypeChange,
  onTargetReturnChange,
  onTargetReturnClear,
  onHoldPeriodChange,
  onHoldPeriodClear,
  propertyTypeOptions,
  propertyTypeRef
}: Props) => {
  const hasActiveFilter =
    [...activePropertyType, ...activeTargetReturn, ...activeHoldPeriod].length >
    0;
  const [mobileFiltersVisible, setMobileFiltersVisible] = useState(false);

  const handlePropertyTypeClose = () => {
    EmAnalytics.track('Applied Property Type Filter', 'Investing', {
      property_types: activePropertyType
    });
  };

  return (
    <div className={styles.filters}>
      <div className={styles.pillarFilters} data-testid="offeringFilters">
        <button
          className={`${styles.pillarButton} ${styles.all} ${activePillar === 'all' ? styles.active : ''}`}
          type="button"
          onClick={() => onPillarChange('all')}
          data-testid="allFilter"
        >
          <IconAll />
          <div className={styles.label}>All</div>
        </button>
        <div className={styles.separator} />
        <button
          className={`${styles.pillarButton} ${styles.keep} ${activePillar === 'keep' ? styles.active : ''}`}
          type="button"
          onClick={() => onPillarChange('keep')}
          data-testid="keepFilter"
        >
          <IconKeep />
          <div>
            <div className={styles.label}>Keep</div>
            <div className={styles.description}>
              A real estate-backed short-term note. A solid cash management tool
              with compelling rates, shortest terms.
            </div>
          </div>
        </button>
        <button
          className={`${styles.pillarButton} ${styles.earn} ${activePillar === 'earn' ? styles.active : ''}`}
          type="button"
          onClick={() => onPillarChange('earn')}
          data-testid="earnFilter"
        >
          <IconEarn />
          <div>
            <div className={styles.label}>Earn</div>
            <div className={styles.description}>
              Income-focused and shorter term. Typically Debt or Preferred
              Equity.
            </div>
          </div>
        </button>
        <button
          className={`${styles.pillarButton} ${styles.grow} ${activePillar === 'grow' ? styles.active : ''}`}
          type="button"
          onClick={() => onPillarChange('grow')}
          data-testid="growFilter"
        >
          <IconGrow />
          <div>
            <div className={styles.label}>Grow</div>
            <div className={styles.description}>
              Equity investments targeting growth with a compelling upside
              potential. Typically the longest hold periods.
            </div>
          </div>
        </button>
      </div>
      <Button
        onClick={() => setMobileFiltersVisible(!mobileFiltersVisible)}
        trailingIcon={mobileFiltersVisible ? 'arrowUp' : 'arrowDown'}
        className={styles.mobileFilterToggleButton}
        variant="outlined"
      >
        <IconFilters className={styles.filterIcon} />
        Filters
      </Button>
      <div
        className={`${styles.filterButtons} ${!mobileFiltersVisible ? styles.mobileHidden : ''}`}
        data-testid="filterButtons"
      >
        <FilterSelect
          buttonText="Property Type"
          options={propertyTypeOptions}
          onChange={onPropertyTypeChange}
          value={activePropertyType}
          multi
          className={styles.filterButton}
          data-testid="propertyType"
          ref={propertyTypeRef}
          onMenuClose={handlePropertyTypeClose}
        />
        <FilterRange
          buttonText="Target Return"
          onChange={onTargetReturnChange}
          onClear={onTargetReturnClear}
          minValue={activeTargetReturn[0]}
          maxValue={activeTargetReturn[1]}
          minInputProps={percentageInputProps}
          maxInputProps={percentageInputProps}
          maxAllowedValue={50}
          formattedValue={`${activeTargetReturn[0]}%-${activeTargetReturn[1]}%`}
          cardContent={
            <>
              <h6>Target Return</h6>
              <p>The anticipated return.</p>
            </>
          }
          className={styles.filterButton}
          data-testid="targetReturn"
        />
        <FilterRange
          buttonText="Hold Period"
          onChange={onHoldPeriodChange}
          onClear={onHoldPeriodClear}
          minValue={activeHoldPeriod[0]}
          maxValue={activeHoldPeriod[1]}
          minInputProps={monthsInputProps}
          maxInputProps={monthsInputProps}
          maxAllowedValue={120}
          formattedValue={`${activeHoldPeriod[0]}-${activeHoldPeriod[1]} months`}
          cardContent={
            <>
              <h6>Hold Period</h6>
              <p>The anticipated hold period, in months.</p>
            </>
          }
          className={styles.filterButton}
          data-testid="holdPeriod"
        />
        {hasActiveFilter && (
          <Button
            variant="outlined"
            className={styles.clearButton}
            onClick={onClearFilter}
          >
            <ClearFilter />
            Clear Filters
          </Button>
        )}
      </div>
    </div>
  );
};

export default OfferingFilters;
