import { Card } from '@equitymultiple/react-eui';
import stepsIllustration from 'images/steps-illustration.png';
import React from 'react';

import * as styles from './NotAccreditedCard.module.scss';

const NotAccreditedCard = () => {
  return (
    <Card className={styles.card}>
      <div className={styles.content}>
        <img src={stepsIllustration} alt="" />
        <h6>You're one step away from investments</h6>
        <p>
          There are a lot of investment opportunities waiting for you. Update
          your accreditation status and start exploring.
        </p>
      </div>
    </Card>
  );
};

export default NotAccreditedCard;
