export const lrxSendPage = () => {
  const lrxId = process.env.REACT_APP_LEADSRX_ID;

  if (lrxId) {
    window._lab = window._lab || [];

    const loadScript = () => {
      window._lab.push(lrxId);
      const lab = document.createElement('script');
      lab.type = 'text/javascript';
      lab.async = true;
      lab.src =
        ('https:' == document.location.protocol
          ? 'https://app'
          : 'http://app') + '.leadsrx.com/visitor.js';
      const s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(lab, s);
    };

    if (window._lrx_sendEvent) {
      try {
        setTimeout(() => {
          window._lrx_sendEvent('page', document.location.pathname);
        }, 2000);
      } catch {} // eslint-disable-line
    } else {
      loadScript();
    }
  }
};
