import { ActivePillar } from 'containers/Invest/types';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { InvestArticle } from 'types/api/offering';

import * as styles from './ResourceCards.module.scss';

interface Props {
  activePillar: ActivePillar;
  articles: InvestArticle[];
  loading: boolean;
}

const LoadingSkeleton = () => (
  <div className={styles.cards} data-testid="resourceCardsLoadingSkeleton">
    <div className={styles.card}>
      <span className={styles.label}>
        <Skeleton />
      </span>
      <hr />
      <h6>
        <Skeleton />
        <Skeleton />
      </h6>
    </div>
    <div className={styles.card}>
      <span className={styles.label}>
        <Skeleton />
      </span>
      <hr />
      <h6>
        <Skeleton />
        <Skeleton />
      </h6>
    </div>
    <div className={styles.card}>
      <span className={styles.label}>
        <Skeleton />
      </span>
      <hr />
      <h6>
        <Skeleton />
        <Skeleton />
      </h6>
    </div>
  </div>
);

const ResourceCards = ({ articles, activePillar, loading }: Props) => {
  const visibleArticles = articles?.filter(
    article => activePillar === 'all' || article.pillar.includes(activePillar)
  );

  return loading ? (
    <LoadingSkeleton />
  ) : (
    <div className={styles.cards}>
      {visibleArticles?.map(article => (
        <a
          className={styles.card}
          href={article.url}
          target="_blank"
          rel="noreferrer"
          key={article.title}
        >
          <span className={styles.label}>{article.label}</span>
          <hr />
          <h6>{article.title}</h6>
        </a>
      ))}
    </div>
  );
};

export default ResourceCards;
