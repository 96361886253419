import AccountProgress from 'components/AccountProgress/AccountProgress';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-grid-system';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { loadMarketingData, loadOfferings } from 'redux/actions/invest';
import { InvestArticle, InvestOffering } from 'types/api/offering';
import { User } from 'types/api/user';
import { Dispatch } from 'types/redux';
import EmAnalytics from 'utilities/em_analytics';
import { isAccredited } from 'utilities/user';
import utils from 'utilities/utils';

import { WelcomeLetter } from './components';
import NoOfferings from './components/NoOfferings/NoOfferings';
import NotAccreditedCard from './components/NotAccreditedCard/NotAccreditedCard';
import OfferingFilters from './components/OfferingFilters/OfferingFilters';
import OfferingList from './components/OfferingList/OfferingList';
import ResourceCards from './components/ResourceCards/ResourceCards';
import { isAnnualizedYield } from './helpers';
import * as styles from './Invest.module.scss';
import { ActivePillar } from './types';

interface SelectInstance {
  clearValue: () => void;
}

type Props = {
  articles: InvestArticle[];
  dispatch: Dispatch;
  loadingArticles: boolean;
  loadingOfferings: boolean;
  offerings: InvestOffering[];
  user: User;
};

const Invest = ({
  articles,
  dispatch,
  loadingArticles,
  loadingOfferings,
  offerings,
  user
}: Props) => {
  const propertyTypeRef = useRef<SelectInstance>();
  const location = useLocation();
  const [activePillar, setActivePillar] = useState<ActivePillar>('all');
  const [filteredPropertyType, setFilteredPropertyType] = useState([]);
  const [filteredTargetReturn, setFilteredTargetReturn] = useState([]);
  const [filteredHoldPeriod, setFilteredHoldPeriod] = useState([]);
  const [propertyTypeOptions, setPropertyTypeOptions] = useState([]);

  let showWelcomeLetter = false;
  const query = queryString.parse(location.search);
  if (query?.show === 'welcome' && user.investor_profile.stage === 'invest') {
    const welcomeLetterShown =
      utils.getCookie('welcome-letter-shown') === user.id.toString();
    if (!welcomeLetterShown) showWelcomeLetter = true;
  }

  useEffect(() => {
    document.title = 'Invest | EquityMultiple';
    document.querySelector('body').style.backgroundColor = '#f8f8f8';

    dispatch(loadOfferings());
    dispatch(loadMarketingData());
  }, [dispatch]);

  useEffect(() => {
    if (offerings) {
      const newPropertyTypeOptions = [];
      offerings.forEach(offering => {
        const assetType = offering.asset_type;
        if (
          !newPropertyTypeOptions.some(option => option.value === assetType)
        ) {
          newPropertyTypeOptions.push({
            label: assetType,
            value: assetType
          });
        }
      });

      setPropertyTypeOptions(newPropertyTypeOptions);
    }
  }, [offerings]);

  const showAccountProgress = user?.investor_profile?.stage === 'activate';

  const showOfferings = isAccredited(user);

  const handlePillarChange = (pillar: ActivePillar) => {
    const newPillar =
      pillar !== 'all' && pillar === activePillar ? 'all' : pillar;

    setActivePillar(newPillar);

    EmAnalytics.track('Applied Pillar Filter', 'Investing', {
      pillar: newPillar
    });
  };

  const handleClearFilter = () => {
    setActivePillar('all');
    setFilteredPropertyType([]);
    setFilteredTargetReturn([]);
    setFilteredHoldPeriod([]);
    if (propertyTypeRef?.current) propertyTypeRef?.current.clearValue();
  };

  const handlePropertyTypeChange = value => {
    setFilteredPropertyType(value);
  };

  const handleTargetReturnChange = (minValue, maxValue) => {
    setFilteredTargetReturn([minValue, maxValue]);

    EmAnalytics.track('Applied Return Filter', 'Investing', {
      return_lower_bound: `${minValue}%`,
      return_upper_bound: `${maxValue}%`
    });
  };

  const handleHoldPeriodChange = (minValue, maxValue) => {
    setFilteredHoldPeriod([minValue, maxValue]);

    EmAnalytics.track('Applied Hold Period Filter', 'Investing', {
      hold_lower_bound: `${minValue} month(s)`,
      hold_upper_bound: `${maxValue} month(s)`
    });
  };

  const handleTargetReturnClear = () => setFilteredTargetReturn([]);

  const handleHoldPeriodClear = () => setFilteredHoldPeriod([]);

  const getFilteredOfferings = () => {
    let filteredOfferings = offerings;

    filteredOfferings = offerings.filter(offering => {
      // Pillar
      const pillarMatches =
        activePillar === 'all' || offering.pillar === activePillar;

      // Property Type
      const propertyTypeMatches =
        !filteredPropertyType.length ||
        filteredPropertyType.includes(offering.asset_type);

      // Target Return
      let returnMetric = offering.rate;
      const strategies = offering.strategy?.split(',');
      const isEquity = strategies.includes('Equity');

      if (isAnnualizedYield(offering)) {
        returnMetric = offering.projected_annual_return;
      } else if (isEquity) {
        returnMetric = offering.irr;
      }

      const returnValue = utils.parseToNumber(returnMetric);
      const targetReturnMatches =
        !filteredTargetReturn.length ||
        (filteredTargetReturn[0] <= returnValue &&
          filteredTargetReturn[1] >= returnValue);

      // Hold Period
      let holdPeriod = Number(offering.term);
      if (offering.term_period === 'Year(s)') holdPeriod = holdPeriod * 12;
      const holdPeriodMatches =
        !filteredHoldPeriod.length ||
        (filteredHoldPeriod[0] <= holdPeriod &&
          filteredHoldPeriod[1] >= holdPeriod);

      return (
        pillarMatches &&
        propertyTypeMatches &&
        targetReturnMatches &&
        holdPeriodMatches
      );
    });

    return filteredOfferings;
  };

  const filteredOfferings = getFilteredOfferings();

  const hasOfferings = filteredOfferings?.length > 0;

  return (
    <div>
      {showWelcomeLetter && (
        <WelcomeLetter
          name={user.first_name}
          userId={user.id}
          irRep={user.investor_profile?.representative_meeting_link}
        />
      )}
      <Container className="container-wide">
        {showAccountProgress && (
          <AccountProgress user={user} signupStage="invest" />
        )}

        {showOfferings ? (
          <>
            <div className="text-center">
              <h1 className={styles.heading}>
                Get Ready for Easier Real Estate Investing
              </h1>
              <div className={styles.sectionSubheading}>
                Investments to fit your strategy and goals.
              </div>
            </div>
            <OfferingFilters
              activePillar={activePillar}
              onPillarChange={handlePillarChange}
              onClearFilter={handleClearFilter}
              propertyTypeOptions={propertyTypeOptions}
              activePropertyType={filteredPropertyType}
              onPropertyTypeChange={handlePropertyTypeChange}
              onTargetReturnChange={handleTargetReturnChange}
              onTargetReturnClear={handleTargetReturnClear}
              onHoldPeriodChange={handleHoldPeriodChange}
              onHoldPeriodClear={handleHoldPeriodClear}
              activeTargetReturn={filteredTargetReturn}
              activeHoldPeriod={filteredHoldPeriod}
              propertyTypeRef={propertyTypeRef}
            />
            {hasOfferings || loadingOfferings ? (
              <div className={styles.contentWrap}>
                <section className={styles.mainContent}>
                  <OfferingList
                    offerings={filteredOfferings}
                    user={user}
                    loading={loadingOfferings}
                  />
                </section>
                <aside className={styles.sidebar}>
                  <ResourceCards
                    articles={articles}
                    activePillar={activePillar}
                    loading={loadingArticles}
                  />
                </aside>
              </div>
            ) : (
              <NoOfferings onClearFilter={handleClearFilter} />
            )}
          </>
        ) : (
          <NotAccreditedCard />
        )}
      </Container>
    </div>
  );
};

function mapStateToProps(store) {
  return {
    articles: store.invest.articles,
    loadingArticles: store.invest.loadingArticles,
    loadingOfferings: store.invest.loadingOfferings,
    offerings: store.invest.offerings,
    user: store.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(Invest);
