import { AnyAction } from 'redux';

import * as actions from '../actions/invest';

const initialState = {
  articles: [],
  loadingOfferings: false,
  offerings: []
};

export default (
  state = initialState,
  action: AnyAction = {
    type: null
  }
) => {
  switch (action.type) {
    case actions.LOAD_OFFERINGS:
      return {
        ...state,
        loadingOfferings: true
      };

    case actions.LOAD_OFFERINGS_SUCCESS:
      return {
        ...state,
        loadingOfferings: false,
        offerings: action.result
      };

    case actions.LOAD_OFFERINGS_FAIL:
      return {
        ...state,
        loadingOfferings: false
      };

    case actions.LOAD_MARKETING_DATA:
      return {
        ...state,
        loadingArticles: true
      };

    case actions.LOAD_MARKETING_DATA_SUCCESS:
      return {
        ...state,
        loadingArticles: false,
        articles: action.result.acf.article_cards
      };

    case actions.LOAD_MARKETING_DATA_FAIL:
      return {
        ...state,
        loadingArticles: false
      };

    default:
      return state;
  }
};
