import { Button } from '@equitymultiple/react-eui';
import ClearFilter from 'images/icons/clear-filter.svg';
import SearchIllustration from 'images/search-illustration.svg';
import React from 'react';

import * as styles from './NoOfferings.module.scss';

interface Props {
  onClearFilter: () => void;
}

const NoOfferings = ({ onClearFilter }: Props) => {
  return (
    <div className={styles.noOfferings}>
      <h1>No offerings found</h1>
      <SearchIllustration />
      <p>
        No results match your selected filters. Try adjusting your filters or
        clearing them to explore more options.
      </p>
      <Button className={styles.clearButton} onClick={onClearFilter}>
        <ClearFilter />
        Clear Filters
      </Button>
    </div>
  );
};

export default NoOfferings;
