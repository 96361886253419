import React from 'react';
import { Col, Row } from 'react-grid-system';
import { InvestOffering } from 'types/api/offering';
import { User } from 'types/api/user';

import { OfferingCard } from '..';
import * as styles from './OfferingList.module.scss';

export type Props = {
  loading: boolean;
  offerings: InvestOffering[];
  user: User;
};

const LoadingSkeleton = () => (
  <>
    <Col xl={4} md={6} className={styles.offeringCardColumn}>
      <OfferingCard loading />
    </Col>
    <Col xl={4} md={6} className={styles.offeringCardColumn}>
      <OfferingCard loading />
    </Col>
    <Col xl={4} md={6} className={styles.offeringCardColumn}>
      <OfferingCard loading />
    </Col>
  </>
);

const OfferingList = ({ offerings, user, loading }: Props) => {
  const highlightedOffering = offerings.find(
    offering => offering.is_highlighted
  );
  const otherOfferings = offerings.filter(offering => !offering.is_highlighted);

  return (
    <div>
      <section className={styles.offeringsList}>
        {highlightedOffering && (
          <OfferingCard
            currentUser={user}
            offering={highlightedOffering}
            cardIndex={1}
            isHighlighted
          />
        )}
        <Row className={styles.offeringCardRow}>
          {loading ? (
            <LoadingSkeleton />
          ) : (
            otherOfferings.map((offering, index) => (
              <Col
                xl={4}
                md={6}
                key={offering.id}
                className={styles.offeringCardColumn}
              >
                <OfferingCard
                  currentUser={user}
                  offering={offering}
                  cardIndex={highlightedOffering ? index + 2 : index + 1}
                />
              </Col>
            ))
          )}
        </Row>
      </section>
    </div>
  );
};

export default OfferingList;
