import moment, { Moment } from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import * as styles from './CapitalCallTimer.module.scss';

interface Props {
  deadline: Moment;
  small?: boolean;
}

const CapitalCallTimer = ({ small, deadline }: Props) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [warning, setWarning] = useState(false);
  const [pastEnd, setPastEnd] = useState(false);

  const setTime = () => {
    // Countdown runs until end of day on the funding deadline
    const end = moment.tz(deadline, 'America/New_York').endOf('day');
    const now = moment();
    let newDays = 0;
    let newHours = 0;
    let newMinutes = 0;
    let newWarning = true;
    let newPastEnd = true;

    const diff = end.diff(now);

    if (Math.sign(diff) === 1) {
      const diffDuration = moment.duration(diff);
      // Since we're showing exact hours, round down the number of days to the nearest integer.
      // This will show 0 if under 24 hours
      newDays = Math.floor(diffDuration.asDays());
      newHours = diffDuration.hours();
      newMinutes = diffDuration.minutes();
      newWarning = diffDuration.days() < 3;
      newPastEnd = false;
    }

    setDays(newDays);
    setHours(newHours);
    setMinutes(newMinutes);
    setWarning(newWarning);
    setPastEnd(newPastEnd);
  };

  useEffect(() => {
    let timerInterval = null;

    if (deadline) {
      setTime();
      if (!small) timerInterval = setInterval(setTime, 30000);
    }

    return () => {
      if (timerInterval) clearInterval(timerInterval);
    };
  }, [deadline, small]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={`${styles.capitalCallTimer} ${warning ? styles.orange : ''} ${
        small ? styles.small : ''
      }`}
    >
      {!small && <div className={styles.navTitle}>Capital Call Due In:</div>}
      <div className={styles.timer}>
        {(!small || (small && (days > 0 || pastEnd))) && (
          <div className={styles.tile}>
            <div className={styles.tileLabel}>Days {small && ' Left'}</div>
            <div className={styles.tileValue}>{days}</div>
          </div>
        )}
        {(!small || (small && days === 0 && hours > 0 && !pastEnd)) && (
          <div className={styles.tile}>
            <div className={styles.tileLabel}>Hours {small && ' Left'}</div>
            <div className={styles.tileValue}>{hours}</div>
          </div>
        )}
        {(!small || (small && days === 0 && hours === 0 && !pastEnd)) && (
          <div className={styles.tile}>
            <div className={styles.tileLabel}>Min {small && ' Left'}</div>
            <div className={styles.tileValue}>{minutes}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CapitalCallTimer;
